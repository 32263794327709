@mixin circle($size, $bg) {
  position: absolute;
  border-radius: 50%;
  width: $size;
  height: $size;
  background: $bg;
}

.green-circle {
  position: absolute;
  border-radius: 50%;
  width: 350px;
  height: 350px;
  background: rgb(127, 114, 224, 0.7);
  bottom: -50px;
  left: -50px;
  animation: green-circle 10s ease-in-out infinite;
}

.purple-circle {
  position: absolute;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  background: rgba(172, 75, 214, 0.4);
  bottom: -50px;
  left: 150px;
  box-shadow: 0 0 250px 250px rgba(172, 75, 214, 0.4);
  animation: increase 10s ease-in-out infinite;
}

.yellow-circle {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background: #ff1493;
  top: -50px;
  right: -50px;
  box-shadow: 0 0 60px 70px rgba(230, 69, 209, 0.6);
  animation: increase 10s ease-in-out infinite;
}

.gradient-circle {
  position: absolute;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background: linear-gradient(90deg, #ff1493 0%, #7568dc 100%);
  top: 100px;
  right: -30px;
  animation: gradient-circle 10s ease infinite;
}

.gradient-circle-bottom {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background: linear-gradient(45deg, #7568dc 0%, #ff1493 100%);
  bottom: 250px;
  left: 30px;
  animation: gradient-circle-bottom 10s ease-in-out infinite;
}

.dashed-circle {
  position: absolute;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background: transparent;
  top: 100px;
  right: 50px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-around;
  animation: dashed-circle 10s ease infinite;
}

.dashed-circle .line {
  width: 100%;
  height: 1px;
  background: linear-gradient(45deg, #7568dc 0%, #ff1493 100%);
}

.little-green-circle {
  position: absolute;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: rgb(127, 114, 224, 0.7);
  top: 50px;
  right: 130px;
  animation: little-green 5s ease infinite;
}

@keyframes little-green {
  0%,
  50%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, 0) scale(1.5);
  }
  75% {
    transform: translate(20px, 20px);
  }
}

@keyframes dashed-circle {
  0%,
  38%,
  40%,
  100% {
    transform: translate(0, 0) scale(1);
  }
  20% {
    transform: translate(-10px, 10px) scale(1);
  }
  39% {
    transform: translate(0, 0) scale(1.6);
  }
  60% {
    transform: translate(10px, 10px) scale(1);
  }
}

@keyframes gradient-circle {
  0%,
  40%,
  100% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-10px, 10px);
  }
  60% {
    transform: translate(10px, 10px);
  }
}

@keyframes increase {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}

@keyframes green-circle {
  0%,
  100% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-30px, 10px);
  }
  40% {
    transform: translate(-20px, 5px);
  }
  60% {
    transform: translate(-30px, 10px);
  }
  80% {
    transform: translate(10px, -5px);
  }
}

@keyframes gradient-circle-bottom {
  0%,
  100% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-10px, 20px);
  }
  40% {
    transform: translate(-20px, 30px);
  }
  60% {
    transform: translate(-5px, 20px);
  }
  80% {
    transform: translate(0, 10px);
  }
}
