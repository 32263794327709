@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import url("https://use.fontawesome.com/releases/v5.4.1/css/all.css");

:root {
  --white: #ffffff;
  --dark: #0f0617;
  --light: #e8e0e0;
  --primary: #7568dc;
  --secondary: #fac384;
  --shadow-color: 20, 20, 20; /* Changed to a much darker shade */
  --shadow: 1.4px 2.7px 3.6px rgba(var(--shadow-color), 1),
    /* Increased opacity for darker effect */ 3.8px 7.5px 10px
      rgba(var(--shadow-color), 1),
    9px 18.1px 24.1px rgba(var(--shadow-color), 1),
    30px 60px 80px rgba(var(--shadow-color), 1);
  --shadow-hover: 1.8px 3.7px 3.6px rgba(var(--shadow-color), 1),
    5px 10.1px 10px rgba(var(--shadow-color), 1),
    12.1px 24.4px 24.1px rgba(var(--shadow-color), 1),
    40px 81px 80px rgba(var(--shadow-color), 1);
    box-shadow: #e2dddd;

}

.GT {
  position: relative;
  z-index: 12;
  padding: 20px;
  padding-top: 20px;
  background-color:black;
}
.mxxx {
  cursor: pointer;
}
.button {
  color: var(--primary);
  font-size: 14px;
  font-weight: 700;
  padding: 0.75rem 2rem;
  border-radius: 2rem;
  border: 2px solid var(--primary);
  background: transparent;
}

.button.--active {
  border-color: var(--primary);
  background: var(--primary);
  color: var(--light);
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10rem;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
}

@media (min-width: 1400px) {
  .grid {
    grid-template-columns: repeat(5, 1fr);
    gap: 3rem;
  }
}

.card {
  display: flex;
  flex-direction: column;
  background: black;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 8px 24px;
  color: white;
  text-decoration: none;
  transition: all 0.25s;
  position: relative;
  border: 1px solid #ff1493;
  max-height:120% ;
  padding: 0%;
  margin: 0%;
}

.card__picture {
  display: block;
  width: 100%;
  height: 50%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
  transition: background 0.25s;
  padding: 1px;
}

.card__picture:after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    rgba(var(--dark-rgb), 0) 25%,
    rgba(var(--dark-rgb), 0)
  );
  z-index: 1;
}

.card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.25s;
}

.card__content {
  padding: 1rem;
  position: relative;
  font-family: "gilroyregular";
}

.card__title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding-top: 3px;
}

.card__more {
  position: relative;
  margin: -1.5rem 1.5rem -1.5rem 0;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background: #ff1493;
  color: var(--white);
  align-self: flex-end;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.25s;
  z-index: 2;
}

@media (min-width: 992px) {
  .card__more {
    margin: -2rem 1.5rem -2rem 0;
    height: 4rem;
    width: 4rem;
  }
}

.card__more:after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1rem;
}

@media (min-width: 992px) {
  .card__more:after {
    font-size: 1.5rem;
  }
}

.card__location {
  color: #7568dc;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.card__location:before {
  content: "\f3c5";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 0.75rem;
}

.card.--inactive {
  display: none;
}

.card:hover {
  box-shadow:   #ff1493 0px 8px 24px;
  
}

.card:hover .card__picture:after {
  background: linear-gradient(
    rgba(var(--primary-rgb), 0) 25%,
    rgba(var(--primary-rgb), 0.5)
  );
}

.card:hover .card__image {
  transform: scale(1.1);
}
