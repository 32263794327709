form ul li select option{
  background-color: black !important;
  color: white !important;
}



/* styles.module.css */
.cH {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 50px;
}

.contactForm {
  width: 100;
  margin: 0 auto;
  padding-bottom: 100px;
}

form ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.M_C {
  position: relative;
  z-index: 12;
}

form li {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  transition: all 0.3s;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}

.haifW {
  flex: 1 1 45%; /* Take up roughly half the width with some margin */
  margin-right: 5%;
}
.haifW input {
  /* width: 100%; */
  height: 55px;
  background: hsla(0, 0%, 100%, 0.1);
  border: 1px solid #4e4e4e;
  outline: none;
  color: #fff;
  padding: 10px 3%;
  font-size: 15px;
  font-family: gilroy_regularregular, Cairo;
  border-radius: 40px;
}
li select{
  height: 55px;
    background: hsla(0, 0%, 100%, 0.1);
    border: 1px solid #4e4e4e;
    outline: none;
    color: #858383;
    /* padding: 10px 3%; */
    
    font-size: 15px;
    font-family: gilroy_regularregular, Cairo;
    border-radius: 40px;
}

.custom_select {
  /* padding-right: 30px; Adjust this value as needed */
  position: relative; /* Ensure positioning context for the custom arrow */
}

.custom_select select {
  width: 100%; /* Ensure the select takes full width */
  padding-right: 30px; 
  appearance: none; /* Remove the default arrow */
  -webkit-appearance: none; /* Remove the default arrow in WebKit browsers */
  -moz-appearance: none; /* Remove the default arrow in Firefox */
  padding: 12px;
}

.custom_select::after {
  content: '▼'; /* Custom arrow symbol */
  position: absolute;
  right: 20px; /* Position the arrow on the right */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: white; /* Ensure the arrow does not interfere with the select element */
}
.error {
  color: #eb2222;
  padding: 10px;
}
.fullW {
  flex: 1 1 100%; /* Take up full width */
}
.fullW textarea {
  border-radius: 10px;

  height: 175px;
  background: hsla(0, 0%, 100%, 0.1);
  border: 1px solid #4e4e4e;
  outline: none;
  color: #fff;
  padding: 10px 3%;
  font-size: 15px !important;
  resize: none;
  font-family: gilroy_regularregular, Cairo;
}

.haifW:nth-child(2n) {
  margin-right: 0; /* Remove right margin for every second item */
}
.placeHolderText {
  position: absolute;
  margin-top: 20px;
  margin-left: 20px;
  color: white;
}
.contactForm button {
  height: 47px;
  padding: 0 35px;
  background: transparent;
  cursor: pointer;
  height: 55px;
  border: 1px solid #fff;
  color: #fff;
  display: inline-flex;
  padding: 0 35px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-radius: 100px;
  color: #fff !important;
  transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -webkit-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
}
.contactForm button:hover {
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  transition: 0.5s ease-in-out;
}
.btn {
  text-align: left;
}
/* Adjust for smaller screens */
@media (max-width: 600px) {
  .haifW {
    flex: 1 1 100%; /* Take up full width on small screens */
    margin-right: 0;
  }
  .cH {
    margin-bottom: 20px;
    margin-top: 0px;
  }
}
@media (min-width: 1440px) {
  .contactForm {
    width: 60%;
  }
}
