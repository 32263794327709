.Home {
  background-color: #000;
  min-height: 100vh;
}
.seven_p {
  padding-left: 7%;
  padding-right: 7%;
  position: relative;
  z-index: 12;
}
@media (min-width: 1440px) {
  .seven_p {
    padding-left: 3%;
    padding-right: 3%;
  }
}
.no-scrollbar .scrollbar-track {
  display: none !important;
}
.ct {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Hide any overflow */
}

.gt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
