.brand p {
  margin-bottom: 20px;
  margin-top: 0;
  font-weight: 400;
  line-height: 24px;
}
.brand ul li {
  font-size: 15px;
  line-height: 18px;
  list-style-type: disc;
}
.brand h5 {
  background: linear-gradient(94deg, #ff47f8 0.01%, #8360f8 70.32%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  color: #fff;
  font-family: "gilroybold";
  margin: 0 0 30px;
}
.brand ul {
  display: flex;
  flex-wrap: wrap;
}

.brand ul li {
  width: 50%;
  margin-bottom: 9px;
}
.ful {
  width: 100%;
}
.ful li {
  list-style-type: none !important;
}
.bg {
  position: relative;
}
.rel {
  position: absolute;
  bottom: 0;
}
.bg {
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 80px;
}
.brand p {
  color: white;
}
.brand ul li {
  color: white;
}
.brand_cont {
  display: flex;
}
.brand_cont div {
  width: 50%;
}
.brand {
  background: url(../../../assets/images/what-we-do-bg-01.png) right top
    no-repeat rgb(0, 0, 0);
  min-height: 100vh;
}
.bg {
}
.brand h1 {
  font-size: 110px;
  line-height: 100px;
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 35px;
}
.brand button {
  height: 47px;
  padding: 0 35px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 30px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 80px;
}
.brand button:hover {
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  transition: 0.5s ease;
}
.cb {
  padding-left: 7%;
  padding-right: 7%;
}
.cb h3 {
  font-size: 49px;
  line-height: 55px;
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  padding: 30px 0px;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.cb p {
  font-size: 18px;
}
.bgg {
  background: url(../../../assets/images/service_bg_03.png) left bottom
    no-repeat;
  background-size: cover;
  margin-top: 200px;
  border-top-right-radius: 50%;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
@media (min-width: 1440px) {
  .brand h1 {
    font-size: 140px;
    padding-bottom: 35px;
    line-height: 125px;
    background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .cb {
    padding-left: 3%;
    padding-right: 3%;
  }
  .bg {
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 80px;
  }
  .brand p {
    margin-bottom: 20px;
    margin-top: 0;
    font-weight: 400;
    line-height: 24px;
  }

  .brand ul li {
    font-size: 20px;
  }
  .brand h5 {
    background: linear-gradient(94deg, #ff47f8 0.01%, #8360f8 70.32%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    color: #fff;

    margin: 0 0 30px;
  }
}
@media (max-width: 600px) {
  .brand_cont {
    flex-direction: column;
  }
  .brand_cont div {
    width: 100%;
  }
  .brand h1 {
    font-size: 47px;
    line-height: 54px;
    padding-bottom: 0;
    letter-spacing: -2px;
    padding-bottom: 10px;
  }
  .brand h5 {
    margin-bottom: 14px;
    font-size: 38px;
  }
  .brand ul li {
    font-size: 15px;
    line-height: 18px;
  }
  .bgg {
    margin-top: 80px;
  }
  .cb h3 {
    font-size: 30px !important;
    line-height: 33px !important;
    margin-bottom: 20px;
  }
}
