.container {
  background-color: #000;
  color: #fff;
  /* padding: 4rem 2rem; */
  margin: 0 auto;
  width: 100%;
  padding-bottom: 30px;
}

.content {
  position: relative;
  z-index: 10;
}
.relative {
  position: relative;
}
.heading {
  margin-bottom: 36px;
}

.title {
  margin-bottom: 75px;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
.grid .card {
  transition: opacity 1.5s, transform 1.5s !important; /* Apply transition to opacity and transform */
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: calc(300px * 4 + 2rem * 2);
}
.card {
  padding: 0 0 15px 45px;
  border-bottom-width: 0.25rem;
  border-color: #4a5568;

  border-color: #2d3748;
  transition-property: box-shadow;
  transition-duration: 0.3s;
  padding-bottom: 15px;
  margin-bottom: 60px;
}
.grid .card {
  position: relative; /* Add this line */
  margin-right: 4%;
}
.grid .card::after {
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  display: block;
  bottom: 0;
}

.card:hover {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
}

.backgroundImage {
  position: absolute;
  right: -29px;
  top: 14px;
  max-width: 108px;
  max-height: 108px;
}

.cardContent {
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 1rem;
}

.icon {
  max-width: 30px;
  max-height: 30px;
  position: absolute;
  left: -45px;
  top: 13px;
}

.cardTitle {
}

.description {
}

.button {
  text-align: start;
}

.contactButton {
}
/* styles.module.css */
.hidden {
  opacity: 0;
  visibility: hidden;
}

.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  animation-count: 1;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-8%);
  }
  65% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
  }
  50% {
    -webkit-transform: translateY(-8%);
  }
  65% {
    -webkit-transform: translateY(4%);
  }
  80% {
    -webkit-transform: translateY(-4%);
  }
  95% {
    -webkit-transform: translateY(2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
@media (max-width: 1024px) {
  .title {
    margin-bottom: 35px;
  }
  .container {
    padding-bottom: 20px;
  }
  .cardTitle {
    font-size: 29px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .description {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (max-width: 600px) {
  .cardTitle {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0;
    margin-bottom: 10px;
  }
  .description {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  }
  .grid {
    gap: 0;
  }
  .card {
    padding-bottom: 5px;
    margin-bottom: 30px;
  }
  .backgroundImage {
    right: 128px;
    top: 100px;
  }
}
@media (min-width: 1440px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
