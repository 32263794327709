/* Update the existing social icons CSS */
.social-icon-twitter svg {
    fill: #1DA1F2;
  }
  
  .social-icon-twitter:hover {
    background-color: #1DA1F2;
    box-shadow: 0 0 20px rgba(29, 161, 242, 0.7);
  }
  .social-icons {
    display: flex;
    justify-content: right;
    gap: 2rem;
    margin-top: 2rem;
    margin-right: 80px;
    margin-top: 40px;
  }
  
  .social-icon-wrapper {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  
  .social-icon, .social-icon-reflection .social-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    /* border: 1px solid rgba(255, 255, 255, 0.2); */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .social-icon::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.05) 50%,
      transparent 100%
    );
    transform: rotate(45deg);
    transition: all 0.3s ease;
  }
  
  .social-icon:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2);
  }
  
  .social-icon:hover::before {
    top: -100%;
    left: -100%;
  }
  
  .social-icon svg, .social-icon-reflection .social-icon svg {
    width: 20px;
    height: 20px;
    z-index: 1;
    transition: all 0.3s ease;
  }
  
  .social-icon:hover svg {
    transform: scale(1.1);
  }
  
  .social-icon-reflection {
    width: 60px;
    height: 60px;
    margin-top: 1px;
    border-radius: 50%;
    transform: scaleY(-1);
    opacity: 0.3;
    filter: blur(1px);
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
  }
  
  .social-icon-reflection::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.5));
  }
  
  .social-icon-wrapper:hover .social-icon-reflection {
    transform: scaleY(-0.7);
    opacity: 0.5;
  }
  
  /* Brand-specific styles */
  .social-icon-linkedin svg, .social-icon-reflection .social-icon-linkedin svg {
    fill: #0077B5;
  }
  
  .social-icon-instagram svg, .social-icon-reflection .social-icon-instagram svg {
    fill: #E4405F;
  }
  
  .social-icon-facebook svg, .social-icon-reflection .social-icon-facebook svg {
    fill: #1877F2;
  }
  
  /* Hover effects with background fill */
  .social-icon-linkedin:hover {
    background-color: #0077B5;
    box-shadow: 0 0 20px rgba(0, 119, 181, 0.7);
  }
  
  .social-icon-instagram:hover { 
    background-color: #E4405F;
    box-shadow: 0 0 20px rgba(228, 64, 95, 0.7);
  }
  
  .social-icon-facebook:hover {
    background-color: #1877F2;
    box-shadow: 0 0 20px rgba(24, 119, 242, 0.7);
  }
  
  /* Change SVG fill color on hover */
  .social-icon:hover svg {
    fill: white;
  } 