.container {
  display: flex;
  position: relative;
  height: 100vh;
  background-color: #000;
  z-index: 22222222;
}

.left,
.right {
  flex: 1;
  position: relative;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2222222;
  animation: slideIn 2s forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.exiting {
  animation: slideOut 2s forwards;
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 20px;
}

.circle {
  position: absolute;
  top: 50%;
  left: 48.5%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.circle:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

.circle span {
  color: #000;
  font-weight: bold;
}

.closeButton {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 40px;
  color: #000;
  cursor: pointer;
  z-index: 22222222;
  background-color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.muteButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  z-index: 22222222;
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  .container {
    height: 45vh;
    z-index: 1111111;
  }
}
@media (min-width: 1440px) {
}
