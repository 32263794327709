.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}

.grid {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.grid .Image {
  width: 32%;
  text-align: center;
}
.Image img {
  width: auto;
  height: 350px;
  max-width: 100%;
}
.Image img:hover {
  opacity: 0.8;
  transition: 0.3s ease;
}
.grid .Image:nth-of-type(2) {


  background-color: #5cb8b2;
  border-radius: 12px;
}
.grid .Image:nth-of-type(1) {
  background-color: #167297;
  border-radius: 12px;
 
}
.grid .Image:nth-of-type(3) {
  background-color: #01aaed;
  border-radius: 12px;
  
}

.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  animation-count: 1;
}
.imageWrapper {
  position: relative;
  overflow: hidden;
}

/* Ensure the heading is hidden initially */
.heading {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(
    90deg,
    #000000 0%,
    #000000 100%
  ); /* Semi-transparent background */
  color: #fff;
  text-align: center;
  transform: translateY(100%); /* Hide heading below the container */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transitions */
  opacity: 0; /* Hidden initially */
}

/* Ensure image fills the wrapper */

/* Hover effect to slide the heading */
.Image:hover .heading {
  transform: translateY(-200%); /* Slide the heading up out of the image */
  opacity: 1; /* Make the heading visible */
}
.Image img {
  transition: transform 1s ease; /* Adjust the duration and easing as needed */
  padding: 30px;
}

.Image:hover img {
  transform: scale(1.3); /* Optional: Zoom effect on hover */
}
@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-8%);
  }
  65% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
  }
  50% {
    -webkit-transform: translateY(-8%);
  }
  65% {
    -webkit-transform: translateY(4%);
  }
  80% {
    -webkit-transform: translateY(-4%);
  }
  95% {
    -webkit-transform: translateY(2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
.Company {
  position: relative;
  z-index: 12;
}
.title {
  text-align: center;
  margin: 50px;
}
@media (max-width: 600px) {
  .company_flex {
    flex-direction: column;
  }
  .company_flex div {
    width: 100% !important;
  }
  .Company h2 {
    word-spacing: 0px;
  }
  .grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 0rem;
  }
  .icon {
    max-width: 24px;
    max-height: 30px;
    position: absolute;
    left: -43px;
    top: 3px;
  }
  .grid .card {
    padding-bottom: 16px;
    margin-bottom: 26px;
    margin-left: 3%;
    margin-right: 3%;
  }
}
@media (min-width: 1440px) {
}
@media (max-width: 768px) {
  .grid {
    flex-direction: column;
    gap: 30px;
  }
  .grid .Image {
    width: 90%;
    margin: 0 auto;
  }
  .Image img {
    height: 250px;
  }
  .Image:hover .heading {
    transform: translateY(-100%); /* Slide the heading up out of the image */
    opacity: 1; /* Make the heading visible */
  }
}
