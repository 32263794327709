.F {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  background-size: cover;
  position: relative;
}

.blackOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transition: opacity 1s ease-in-out;
  z-index: 1;
}

.banner {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 2;
}

.backgroundContainer {
  position: relative;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
}

.background {
  position: absolute;
  width: 40%;
  left: 30%;
  top: 20%;
  animation: moveBackground 5s infinite alternate ease-in-out;
  margin: 0 auto;
  z-index: 10;
}

.image {
  position: absolute;
  width: 60px; /* Adjust size as needed */
}

.image1 {
  top: 10%;
  left: 55%;
  transform: translate(-50%, -50%);
  animation: moveDown 3s infinite alternate ease-in-out;
}

.image2 {
  top: 26%;
  left: 23%;
  /* animation: styles_moveLeft__WiBgH 3s infinite alternate ease-in-out; */
  width: 158px;
  z-index: 111;
  transform: rotate(45deg);
  animation: moveLeft 3s infinite alternate ease-in-out;
}

.image3 {
  top: 52%;
  right: 35%;
  animation: moveRight 4s infinite alternate ease-in-out;
  z-index: 30;
}

.image4 {
  top: 10%;
  left: 27%;
  /* transform: translateX(-50%); */
  /* animation: moveUp 6s infinite alternate ease-in-out; */
  width: 40%;
  z-index: 20;
}
.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  animation-count: 1;
}

@keyframes slideDown {
  0% {
    transform: translateY(-10%);
  }

  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-10%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}
.image5 {
  position: absolute;
  left: 0;
  /* width: 100%; */
  min-width: 100%;
  bottom: 0;
  animation: moveleftright 6s infinite alternate ease-in-out;
  background: no-repeat;
  height: 50%;
}

.image7 {
  width: 40%;
  position: absolute;
  z-index: 111;
  left: 12%;
  transform: rotate(60deg);
  top: 9%;
  transition: transform 3s ease-in-out; /* Add transition for smooth sliding */
}

.image7.slideDown {
  transform: translateY(100px) rotate(45deg); /* Apply sliding down effect */
}

.banner h3 {
  position: absolute;
  bottom: 0;
  font-size: 170px;
  line-height: 235px;
  color: transparent;
  -webkit-text-stroke: 1.8px white;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 3; /* Ensure it is above the overlay */
  transition: opacity 0.5s ease-in-out;
  font-family: metropolislight, Cairo;
}

@keyframes moveleftright {
  0% {
    transform: translateX(-200px);
  }
  50% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-200px);
  }
}

@keyframes moveDown {
  0% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateY(40px);
  }
}

@keyframes moveBackground {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20px);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20px);
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(20px);
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@media (max-width: 1200px) {
  .banner h3 {
    font-size: 100px;
  }
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}

@media (max-width: 600px) {
  .banner h3 {
    font-size: 50px;
    line-height: 66px;
    top: auto;
    bottom: 16%;
    -webkit-text-stroke: 1px #fff !important;
  }
  .background {
    width: 81%;
    left: 15%;
    top: 23%;
  }
  .image4 {
    width: 83%;
    left: 6%;
    top: 18%;
  }
  .image3 {
    top: 33%;
    right: 7%;
    width: 40px;
  }
  .image2 {
    top: 26%;
    left: 11%;

    width: 55px;
  }
  .image1 {
    top: 6%;
    left: 68%;
    width: 50px;
  }
}

@media (min-width: 1440px) {
  .banner h3 {
    font-size: 190px;
    line-height: 235px;
  }
}
