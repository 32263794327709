.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  text-align: center;
  margin: 50px 0px;
  margin-bottom: 310px;
  height: 337px;
}
.services {
  padding-top: 0px;
  margin-right: 120px;
  margin-left: 120px;
}
.badgeContainer {
  position: relative;
  width: 160px;
  height: 160px;
}

.badgeContainerHovered {
  width: 240px; /* Increased size for the scaled-up badge */
  height: 240px; /* Increased size for the scaled-up badge */
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
.badge {
  --badge-size: 160px;
  --badge-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--badge-size);
  height: var(--badge-size);
  background: var(--black);
  border-radius: 100%;
  box-shadow: inset 0 0 0 0.9rem var(--badge-color);
  animation: identifier 20s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  transition: transform 0.3s, width 0.3s, height 0.3s;
}

.badgeContainerHovered .badge {
  --badge-size: 280px; /* Increased size for the scaled-up badge */
  width: var(--badge-size);
  height: var(--badge-size);
}

.badge__emoji {
  display: block;
  margin: 0;
  transition: all 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  width: 60%;
  height: 60%;

  cursor: url("../../../assets/images/Visit-But.png") 16 16, auto; /* Custom cursor */
}

.badge__emojiHovered {
  transform: translate(-37%, -41%) scale(1.2);
}

.badge__char {
  --char-font-weight: 600;
  --char-font-size: 1em;
  --char-width: 10px;
  --char-font-family: "Arial", sans-serif;
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - var(--char-width) / 2);
  height: calc(var(--badge-size) / 2);
  width: var(--char-width);
  text-align: center;
  text-transform: uppercase;
  line-height: 1rem;
  font-weight: var(--char-font-weight);
  font-size: var(--char-font-size);
  color: var(--white);
  font-family: var(--char-font-family);
  transform-origin: bottom center;
  transform: rotate(var(--char-rotate));
  transition: height 0.3s; /* Add transition for smooth resizing */
  opacity: 1;
}

.badgeContainerHovered .badge__char {
  transform: translate(-50%, -50%) scale(1.2) rotate(0deg); /* Scale up and center */
  --char-font-weight: 600;
  --char-font-size: 1em;
  --char-width: 10px;
  --char-font-family: "Arial", sans-serif;
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - var(--char-width) / 2);
  height: calc(var(--badge-size) / 2);
  width: var(--char-width);
  text-align: center;
  text-transform: uppercase;
  line-height: 1rem;
  font-weight: var(--char-font-weight);
  font-size: var(--char-font-size);
  color: var(--white);
  font-family: var(--char-font-family);
  transform-origin: bottom center;
  transform: rotate(var(--char-rotate));
  transition: height 0.3s; /* Add transition for smooth resizing */
  opacity: 1;
}



.popup {
  
  position: absolute;
  top: 190%;  /* Center vertically */
  left: 80%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for centering */
  width: 500px; /* Fixed width for rectangular shape */
  height: 280px; /* Fixed height for rectangular shape */
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  padding: 20px; /* Padding for content */
  border-radius: 12px; /* Optional: rounded corners */
  z-index: 2;
  display: flex;
  flex-direction: column; /* Align items vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: left;
  color: white;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s; 
}



.badgeContainer:hover .popup {
  opacity: 1; /* Make the popup visible on hover */
}
@media (max-width: 600px) {
  .popup {
    width: 70%;
    padding: 12px;
    right: 15%;
  }
  .container {
    height: auto;
    padding-bottom: 100px;
    gap: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@keyframes identifier {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
