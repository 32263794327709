.Company {
  padding-top: 80px;
  position: relative;
  z-index: 12;
}
.company_flex .content {
  width: 55%;
  padding-right: 5%;
  text-align: left;
}
.grid {
  margin-top: 70px !important;
}
.company_flex .image {
  width: 45%;
}
.company_flex .image img {
  width: 100%;
}
button:hover {
  cursor: pointer;
}
.Company h2 {
  word-spacing: 8px;
}
.Company p {
}
.ff img {
  width: 100%;
}
.company_flex {
  display: flex;
  /* flex-wrap: wrap; */
  margin-bottom: 80px;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}
.grid .card {
  transition: opacity 1.5s, transform 1.5s !important; /* Apply transition to opacity and transform */
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
.image {
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 100%; /* Adjust this to fit your design */
  margin: 0 auto;
  margin-right: 3%;
}
.card {
  padding: 0 0 15px 45px;
  border-bottom-width: 0.25rem;
  border-color: #4a5568;

  border-color: #2d3748;
  transition-property: box-shadow;
  transition-duration: 0.3s;
  padding-bottom: 15px;
  margin-bottom: 60px;
}
.grid .card {
  position: relative; /* Add this line */
  margin-right: 4%;
}
.grid .card::after {
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  display: block;
  bottom: 0;
}
.button {
  text-align: left;
  gap: 2rem;
  display: flex;
}
.button .two {
  margin-bottom: 30px;
  height: 47px;
  border: 2px solid hsla(0, 0%, 100%, 0.2);
  /* background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%); */
  color: #fff;
  display: inline-flex;
  padding: 0 30px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-radius: 100px;
  cursor: pointer;
  color: #fff !important;
  font-weight: 600;
  opacity: 0.8;
  background-color: transparent;
}
.button .two:hover {
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  transition: 0.5s ease;
}
.card:hover {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
}

.backgroundImage {
  position: absolute;
  right: -29px;
  top: 14px;
  max-width: 108px;
  max-height: 108px;
}

.cardContent {
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 1rem;
}

.icon {
  max-width: 30px;
  max-height: 30px;
  position: absolute;
  left: -45px;
  top: 13px;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  animation-count: 1;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-8%);
  }
  65% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
  }
  50% {
    -webkit-transform: translateY(-8%);
  }
  65% {
    -webkit-transform: translateY(4%);
  }
  80% {
    -webkit-transform: translateY(-4%);
  }
  95% {
    -webkit-transform: translateY(2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
@media (max-width: 600px) {
  .company_flex {
    flex-direction: column;
  }
  .company_flex div {
    width: 100% !important;
  }
  .Company h2 {
    word-spacing: 0px;
  }
  .grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 0rem;
  }
  .icon {
    max-width: 24px;
    max-height: 30px;
    position: absolute;
    left: -43px;
    top: 3px;
  }
  .grid .card {
    padding-bottom: 16px;
    margin-bottom: 26px;
    margin-left: 3%;
    margin-right: 3%;
  }
}
@media (min-width: 1440px) {
}
