.ourProcess {
  text-align: center;
}
.sections {
  background-image: url(../../../assets/images/line.svg);
  overflow: hidden;
  background-repeat: repeat-y;
  background-position: top center;
  background-size: contain;
  padding: 0px 100px;
}
.centerH {
  text-align: center !important;
}
.ourProcess h3::before {
  top: 0.1em;
  left: 5px;
  width: 0.64em;
  height: 0.64em;
  outline: #eabdf5 solid 1px;
  outline-offset: 0.2em;
  border-radius: 50%;
}
.ourProcess h3 {
  position: relative !important;
  padding-left: 1.4em;
  color: white !important;
  background: linear-gradient(90deg, white 0%, white 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 35px;
  margin-bottom: 20px !important;
}
.ourProcess h3::after {
  position: absolute;
  content: "";
  display: block;
  background-color: #eabdf5;
}
.ourProcess h3::before {
  position: absolute;
  content: "";
  display: block;
  background-color: #eabdf5;
}
.centerP {
  text-align: center !important;
  width: 60% !important;
  margin: 0 auto !important;
}
.proFlexLeft {
  display: flex;
  align-items: center;
  margin-top: 40px;
  gap: 3rem;
}
.proFlexLeftItem {
  width: 50%;
}
.proFlexLeftItem img {
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .ourProcess .sections {
    padding: 0;
  }
  .proFlexLeft {
    flex-direction: column;
  }
  .proFlexLeft div {
    width: 100%;
  }
  .one,
  .three {
    flex-direction: column-reverse !important;
  }
  .centerP {
    width: 100% !important;
  }
  .ourProcess h3 {
    margin-left: 5px;
    font-size: 25px;
  }
}
.bk {
  background-color: black !important;
}
