.Accordion {
}

.accordionContainer {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}

.accordionItem {
  overflow: hidden;
}

.accordionHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f1f1f1;
  cursor: pointer;
  border: 1px solid #4e4e4e;
  background: hsla(0, 0%, 100%, 0.1);
  margin-bottom: 20px;
  padding: 15px 3%;
  border-radius: 10px;
  transition: all 0.3s ease;
}
.flexing {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordionHeader h3 {
  margin: 0;
  font-size: 20px;
  color: #fff;
  font-family: "gilroybold";
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
.icon {
  font-size: 1.5em;
  color: white;
  /* font-weight: 500; */
  align-self: flex-end;
}

.accordionContent {
  padding-top: 10px;
  color: #fff;
  transition: max-height 0.3s ease;
}
.accordionContent p {
  text-align: left;
  margin-bottom: 20px;
  margin-top: 0;
  font-weight: 400;
  line-height: 24px;
}
@media (max-width: 600px) {
  .accordionContainer {
    margin-bottom: 40px;
  }
  .accordionHeader h3 {
    font-size: 15px;
    text-align: left;
  }
}
@media (min-width: 1440px) {
}
