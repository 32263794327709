.WeBel {
  background: #000 url(../../../assets/images/bg_el_04.png) left 25% no-repeat;
  min-height: 100vh;
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 200px;
  position: relative;
  margin-top: 80px;
}
.big {
  font-size: 90px;
  line-height: 90px;
}
.WeBel h5 {
  font-size: 15px;
  line-height: 22px;
  color: white;
}
.weItems h1 {
  font-size: 42px;
  letter-spacing: -2px;
  font-family: "gilroybold";
  line-height: 50px;
  letter-spacing: -3px;
  color: white;
}
.weItem {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 80px;
}
.weItems {
  width: 45%;
  padding-bottom: 15px;
  margin-bottom: 60px;
  position: relative;
}
.WeBel .weItems::after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  display: block;
  bottom: 0;
  background: #555;
}
.rel {
  position: absolute;
  top: 100px;
  left: 35%;
}
@media (min-width: 1440px) {
  .coreS {
    padding-top: 80px;
  }
}
.coreS {
  padding-top: 80px;
}
.c_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c_item div {
  width: 50%;
}
.c_item img {
  width: 100%;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
@media (min-width: 1440px) {
  .WeBel {
    padding-left: 3%;
    padding-right: 3%;
    position: relative;
  }
  .big {
    font-size: 120px !important;
    line-height: 110px !important;
  }
  .WeBel h5 {
    color: #fff;
    font-family: "gilroyregular";
    font-size: 21px;

    font-weight: 400;
  }
  .weItems h1 {
    font-size: 57px;
    letter-spacing: -2px;
    font-family: "gilroybold";
    line-height: 57px;
    letter-spacing: -3px;
    color: white;
  }
}
@media (max-width: 600px) {
  .WeBel {
    padding-top: 0px;
  }
  .weItem {
    flex-direction: column;
  }
  .weItems {
    width: 100%;
  }
  .c_item {
    flex-direction: column;
  }
  .c_item div {
    width: 100%;
  }
  .big {
    font-size: 34px;
    line-height: 39px !important;
    letter-spacing: -2px;
  }
  .weItems h1 {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0;
  }
}
