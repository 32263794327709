.pf_Flex {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 110px;
  position: relative;
  z-index: 2222;
}
.grid,
.imagediv {
  width: 50%;
}
.imagediv img {
  width: 100%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 100%; /* Adjust this to fit your design */
  margin: 0 auto;
  padding-top: 35px;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
.grid .card {
  transition: opacity 1.5s, transform 1.5s !important; /* Apply transition to opacity and transform */
}
.card {
  padding: 0 0 15px 45px;
  border-bottom-width: 0.25rem;
  border-color: #4a5568;

  border-color: #2d3748;
  transition-property: box-shadow;
  transition-duration: 0.3s;
  padding-bottom: 15px;
  margin-bottom: 60px;
  padding-left: 0;
  /* padding-top: 35px; */
}
.cardContent h3 {
  font-size: 100px;
  line-height: 110px;
  font-family: gilroybold, Cairo;
}
.grid .card {
  position: relative; /* Add this line */
  margin-right: 4%;
}
.grid .card::after {
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  display: block;
  bottom: 0;
}
.button {
  text-align: left;
}
.card:hover {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
}

.backgroundImage {
  position: absolute;
  right: -29px;
  top: 14px;
  max-width: 108px;
  max-height: 108px;
}

.cardContent {
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 1rem;
  flex-direction: column;
}
.description {
  font-size: 20px;
  font-weight: 500;
  font-family: gilroyregular, Cario;
}
.head {
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 600px) {
  .pf_Flex {
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 55px;
  }
  .cardContent h3 {
    font-size: 50px;
  }
  .description {
    font-size: 15px;
    font-weight: 400;
  }
  .card {
    margin-bottom: 0px;
    margin-right: 3%;
    margin-left: 3%;
  }
  .grid {
    margin: 0;
    padding: 0;
    gap: 1rem;
    width: 100%;
  }
  .grid,
  .imagediv {
    width: 100%;
  }
  .cardContent {
    gap: 0px;
  }
}
@media (min-width: 1440px) {
}
