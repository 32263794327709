.scroll-container {
  height: 165vh; /* Ensure enough space for scrolling */
  position: relative;
  background-color: #f0f0f0; /* Background color for the scrollable area */
}

.overlay-zoom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120vh;
  background: url("../../../assets/images/eF-mask-4.svg") no-repeat center
    center/cover; /* Replace with your image path */
  z-index: 111111;
}

.content-scroll {
  position: relative;
  z-index: 10;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scroll-container h1 {
  font-size: 140px !important;
  z-index: 11111;
  position: absolute;
  top: 55% !important;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  color: white;
  text-transform: capitalize !important;
  font-family: "metropolislight";
  font-weight: 900 !important;
  letter-spacing: 0px !important;
  line-height: 140px;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire container */
}

.scroll-headline,
.scroll-des,
.scroll-button {
  opacity: 0;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.scroll-headline {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.scroll-des {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.scroll-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.scroll-des video {
  width: 100%;
  height: 100%;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
@media (min-width: 1025px) {
  .scroll-headline,
  .scroll-des,
  .scroll-button {
    opacity: 0;
  }
}

@media (max-width: 1024px) {
  .scroll-container {
    height: 100vh; /* Adjust the height for mobile screens */
    background-color: black;
    overflow: hidden;
  }

  .scroll-container h1 {
    font-size: 50px !important;
    line-height: 50px !important;
  }

  .overlay-zoom {
    height: 100vh; /* Adjust the height for the overlay */
    background-size: cover;
    background: url("../../../assets/images/eF-mask-50Per-nc.svg") no-repeat
      center center/cover !important;
  }

  .background-video {
    object-fit: cover;
  }

  .scroll-des {
    display: block;
  }
}
.mb-v {
  display: none;
}
@media (max-width: 768px) {
  .mb-v {
    display: block;
  }
  .ds-v {
    display: none;
  }
}
