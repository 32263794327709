
h2{
    color: white;
    margin-bottom: 15px;
    margin-top: 15px;
}
/* .first{
    display: flex;
    justify-content: center;
    gap: 100px;

} */
 

 .first .fContent{
    width: 50%;
    padding-left: 50px;
    
 }
 .first .fImage{
    width: 50%;
    text-align: center;
 }
 .first div img{
    width: 40%;
    
 }
 .flordia .first div img{
    width: 80%;
 }
 .videoDiv{
    text-align: center;
 }
 .videoDiv video{
    width: 70%;
 }
 ul{
    padding-left: 50px;
 }
 ul ul{
    padding-left: 25px;
 }
 ul li{
    list-style-type: none!important;
 }
 p {
    color: white;
    margin-top: 30px!important;
 }
 .left_pic1 div{
    width: 50%;
 }
 .right_pic1 div{
    width: 50%;
 }
 .mj h1{
   text-align: center!important;
 }
 
 @media(max-width:798px){
    /* .first .fContent{
        width: 90%;
        padding-left: 0px;
        
     } */
     .first .fImage{
        width: 100%;
        text-align: center;
     }
     .first div img{
        width: 80%;
     }
     .first .fContent{
        width: 100%;
        /* padding-left: 150px; */
        margin: 20px;
        padding: 0;
        padding-left: 20px;
        
     }
     .first2{
        flex-direction: column-reverse!important;
     }
     .left_pic1 div{
        width: 100%;
     }
     .right_pic1 div{
        width: 100%;
     }
     ul {
        padding-left: 20px;
    }
    .videoDiv video{
      width: 100%;
      margin: 50px 0px;
    }
    .right_pic1 img{
      margin: 20px 0px;
    }
    .left_pic1 img{
      margin: 20px 0px;
    }
 }