.Company {
  margin-bottom: 50px;
  position: relative;
  margin-top: 50px;
}
.company_flex div {
  width: 50%;
}
.company_flex div img {
  width: 100%;
}
.Company_ring {
}
.Company_ring img {
  position: absolute;
  left: -50px;
  top: -50px;
}
.Company h1 {
}
.company_flex .content p {
  font-size: 22px !important;
}
button a {
  text-decoration: none;
  color: white;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}
.button {
  text-align: left;
  gap: 2rem;
  display: flex;
  width: 100% !important;
}
.button .two {
  margin-bottom: 30px;
  height: 47px;
  border: 2px solid hsla(0, 0%, 100%, 0.2);
  /* background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%); */
  color: #fff;
  display: inline-flex;
  padding: 0 30px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-radius: 100px;
  cursor: pointer;
  color: #fff !important;
  font-weight: 600;
  opacity: 0.8;
  background-color: transparent;
}
.button .two:hover {
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  transition: 0.5s ease;
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
.company_flex {
  display: flex;
  align-items: center;
}
.content {
  text-align: left;
  padding-right: 5%;
}
/* styles.module.css */
/* styles.module.css */
.hidden {
  opacity: 0;
  visibility: hidden;
}

.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  animation-count: 1;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-8%);
  }
  65% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
  }
  50% {
    -webkit-transform: translateY(-8%);
  }
  65% {
    -webkit-transform: translateY(4%);
  }
  80% {
    -webkit-transform: translateY(-4%);
  }
  95% {
    -webkit-transform: translateY(2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
@media (max-width: 1024px) {
  .Company_ring img {
    top: -72px;
    left: -50px;
  }
}
@media (max-width: 600px) {
  .company_flex {
    flex-direction: column;
  }
  .company_flex div {
    width: 100%;
  }
  .Company_ring img {
    top: -72px;
    left: -13px;
  }
}
@media (min-width: 1440px) {
  .Company_ring img {
    left: 34px;
  }
}
