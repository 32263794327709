.F {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%); */
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.blackOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  transition: opacity 1s ease-in-out;
  z-index: 2;
}
.banner .services_listed{
  
  position: absolute;
  bottom: 25%;
  font-size: 50px;
  line-height: 235px;
  color: transparent;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 3;
  font-family: metropolislight, Cairo;
  /* -webkit-text-stroke: 1.8px rgb(255, 255, 255); */
  transition: opacity 0.5s ease-in-out;
  color: white;

}

.banner {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 2;
}

.backgroundContainer {
  position: relative;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
}

.background {
  position: absolute;
  width: 40%;
  left: 30%;
  top: 20%;
  animation: moveBackground 5s infinite alternate ease-in-out;
  margin: 0 auto;
  z-index: 10;
}

.image {
  position: absolute;
  width: 60px; /* Adjust size as needed */
}

.image1 {
  top: 10%;
  left: 55%;
  transform: translate(-50%, -50%);
  animation: moveDown 3s infinite alternate ease-in-out;
}

.image2 {
  top: 26%;
  left: 23%;
  /* animation: styles_moveLeft__WiBgH 3s infinite alternate ease-in-out; */
  width: 158px;
  z-index: 111;
  transform: rotate(45deg);
  animation: moveLeft 3s infinite alternate ease-in-out;
}

.image3 {
  top: 52%;
  right: 35%;
  animation: moveRight 4s infinite alternate ease-in-out;
  z-index: 30;
}

.image4 {
  top: 10%;
  left: 27%;
  /* transform: translateX(-50%); */
  /* animation: moveUp 6s infinite alternate ease-in-out; */
  width: 40%;
  z-index: 20;
}
.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  animation-count: 1;
}

@keyframes slideDown {
  0% {
    transform: translateY(-25%);
  }

  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-25%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}
.image5 {
  position: absolute;
  left: 0;
  /* width: 100%; */
  min-width: 100%;
  bottom: 0;
  animation: moveleftright 6s infinite alternate ease-in-out;
  background: no-repeat;
  height: 50%;
}

.image7 {
  width: 40%;
  position: absolute;
  z-index: 111;
  left: 12%;
  transform: rotate(60deg);
  top: 9%;
  transition: transform 3s ease-in-out; /* Add transition for smooth sliding */
}

.image7.slideDown {
  transform: translateY(100px) rotate(45deg); /* Apply sliding down effect */
}
.lottie{
  width: 41%;
  margin: 0 auto;
  margin-top: 0px;
  position: relative;
  top: 0%;
  z-index: 2;
  left: 0%;
 
}
.video_background {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  overflow: hidden; /* Ensures no overflow */
}

.video_background video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 11111; /* Sends the video behind other content */
}


.banner h3 {
  position: absolute;
  bottom: 40%;
  font-size: 170px;
  line-height: 235px;
  color: transparent;
  -webkit-text-stroke: 3.8px #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 3; /* Ensure it is above the overlay */
  transition: opacity 0.5s ease-in-out;
  font-family: metropolislight, Cairo;
  /* animation: lightning 3s infinite; Apply the animation */
  /* animation: shine 2s forwards, flicker 3s infinite; */
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    text-shadow: 0 0 0.6rem transparent, 0 0 0.5rem transparent,
      -0.2rem 0.1rem 0.1rem transparent, 0.2rem 0.1rem 0.1rem transparent,
      0 -0.5rem 3rem #f74ba6, 0 0.5rem 5rem #f74ba6;
  }
  28%,
  33% {
    text-shadow: none;
  }
  82%,
  97% {
    text-shadow: none;
  }
}

.flicker {
  animation: shine 4s forwards, blink 5s 5s infinite;
}

@keyframes shine {
  0% {
    text-shadow: none;
  }
  100% {
    text-shadow: 0 0 0.6rem transparent, 0 0 0.5rem transparent,
      -0.2rem 0.1rem 0.1rem transparent, 0.2rem 0.1rem 0.1rem transparent,
      0 -0.5rem 3rem #f74ba6, 0 0.5rem 5rem #f74ba6;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}
@keyframes moveleftright {
  0% {
    transform: translateX(-200px);
  }
  50% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-200px);
  }
}

@keyframes moveDown {
  0% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateY(40px);
  }
}

@keyframes moveBackground {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20px);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20px);
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(20px);
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  animation-count: 1;
  animation-delay: 0s;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-8%);
  }
  65% {
    transform: translateY(4%);
  }
  80% {
    transform: translateY(-4%);
  }
  95% {
    transform: translateY(2%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
  }
  50% {
    -webkit-transform: translateY(-8%);
  }
  65% {
    -webkit-transform: translateY(4%);
  }
  80% {
    -webkit-transform: translateY(-4%);
  }
  95% {
    -webkit-transform: translateY(2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
@media (max-width: 1200px) {
  .banner h3 {
    font-size: 100px;
  }
}

@media (max-width: 600px) {
  .banner h3 {
    font-size: 50px;
    line-height: 66px;
    top: auto;
    bottom: 50%;
    -webkit-text-stroke: 1px #fff !important;
  }
  .background {
    width: 81%;
    left: 15%;
    top: 23%;
  }
  .image4 {
    width: 83%;
    left: 6%;
    top: 18%;
  }
  .image3 {
    top: 33%;
    right: 7%;
    width: 40px;
  }
  .image2 {
    top: 26%;
    left: 11%;

    width: 55px;
  }
  .image1 {
    top: 6%;
    left: 68%;
    width: 50px;
  }
}
.slideDownn {
  font-size: 150px;
  text-align: center;
  font-family: default;
  transform-style: preserve-3d;
  text-shadow: 0 1px 1px #bbb, 0 2px 0 #999, 0 3px 0 #888, 0 4px 0 #777,
    0 5px 0 #666, 0 6px 0 #555, 0 7px 0 #444, 0 8px 0 #333, 0 9px 7px #302314;
  animation: text 4s alternate infinite;
}
.backgroundVideoContainer {
  position: relative;
  width: 100%;
  height: 100vh; /* Full height of the viewport */
  overflow: hidden;
}

.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the entire container */
  z-index: -1; /* Place it behind other content */
}
.backgroundVideoContainer::before {
  content: ""; /* Required for pseudo-elements */
  background-color: rgba(48, 35, 20, 0.2); 
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1; /* Place it above the video but below other content */
}
@keyframes text {
  from {
    transform: rotateX(15deg) rotateY(-25deg);
    color: #fff;
  }
  to {
    transform: rotateX(15deg) rotateY(25deg);
    color: #fff;
  }
}

@media (min-width: 1440px) {
  .banner h3 {
    font-size: 190px;
    line-height: 235px;
    /* text-shadow: 0px 3px 0px #e5e4df, 0px 14px 10px rgb(225 217 217 / 15%),
      0px 24px 2px rgb(243 240 240 / 10%), 0px 34px 30px rgb(239 231 231 / 10%); */
  }
  .slideUp {
    animation-name: slideUp;
    -webkit-animation-name: slideUp;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible !important;
    animation-count: 1;
    animation-delay: 0s;
  }

  @keyframes slideUp {
    0% {
      transform: translateY(100%);
    }
    50% {
      transform: translateY(-8%);
    }
    65% {
      transform: translateY(4%);
    }
    80% {
      transform: translateY(-4%);
    }
    95% {
      transform: translateY(2%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @-webkit-keyframes slideUp {
    0% {
      -webkit-transform: translateY(100%);
    }
    50% {
      -webkit-transform: translateY(-8%);
    }
    65% {
      -webkit-transform: translateY(4%);
    }
    80% {
      -webkit-transform: translateY(-4%);
    }
    95% {
      -webkit-transform: translateY(2%);
    }
    100% {
      -webkit-transform: translateY(0%);
    }
  }
 
}
@media(max-width:768px){
  .banner .services_listed{
    bottom: 28%;
    font-size: 35px;
    line-height: 235px;
  }
}