.container {
	position: relative;
	display: flex;
	height: 100vh;
	width: 100%;
	justify-content: center;
	align-items: center;
	-webkit-box-reflect: below 0 linear-gradient(transparent, transparent, #0005);
}

.loader {
	position: absolute;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	animation: animate 5s linear infinite;
}

.loader:nth-child(2),
.loader:nth-child(4) {
	filter: hue-rotate(290deg);
	animation-delay: -2.4s;
}

@keyframes animate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loader:nth-child(1)::before,
.loader:nth-child(2)::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background: linear-gradient(to top, transparent, rgba(184, 4, 130, 0.8));
	background-size: 100px 180px;
	background-repeat: no-repeat;
	border-top-left-radius: 100px;
	border-bottom-left-radius: 100px;
}

i {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%);
	width: 20px;
	height: 20px;
	background: #7e1770;
	border-radius: 50%;
	z-index: 10;
	box-shadow: 0 0 10px #7e1770,
		0 0 20px #7e1770,
		0 0 30px #7e1770,
		0 0 40px #7e1770,
		0 0 50px #7e1770,
		0 0 60px #7e1770,
		0 0 70px #7e1770,
		0 0 80px #7e1770,
		0 0 90px #7e1770,
		0 0 100px #7e1770;
}

.loader span {
	position: absolute;
	top: 20px;
	left: 20px;
	right: 20px;
	bottom: 20px;
	background-color: #000;
	border-radius: 50%;
	z-index: 1;
}
@media(max-width: 768px){
    
.loader {
	position: absolute;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	animation: animate 2s linear infinite;
}
i {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%);
	width: 20px;
	height: 20px;
	background: #7e1770;
	border-radius: 50%;
	z-index: 10;
	box-shadow: 0 0 10px #7e1770,
		0 0 20px #7e1770,
		0 0 30px #7e1770,
		0 0 40px #7e1770,
		0 0 50px #7e1770,
		0 0 60px #7e1770,
		0 0 70px #7e1770,
		0 0 80px #7e1770,
		0 0 90px #7e1770,
		0 0 100px #7e1770;
}

}