/* Clients.module.css */

.clients-container {
  background-color: #000;
  color: #fff;
  /* padding: 4rem 2rem; */
  margin: 0 auto;
  width: 100%;
  margin-bottom: 150px;
}
.sec {
  margin-bottom: 120px;
}

.clients_heading {
  margin-bottom: 75px;
}

.clients-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  position: relative;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
.client-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  border: 1px solid #323232;
  background: #000;
  z-index: 100;
}

.client-image {
  height: auto;
  max-width: 100%;
}
.backgroundImageright {
  position: absolute;
  right: 0px;
  width: 172px;
  height: 172px;
  top: -92px;
  transform: rotate(180deg);
}

.backgroundImageleft {
  position: absolute;
  left: -150px;
  width: 400px;
  height: 750px;
  top: -120px;
}
.client-item {
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
}

.slideUp {
  opacity: 1;
}

.hidden {
  visibility: hidden;
}
.hidden {
  opacity: 1;
  visibility: hidden;
}

.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  animation-count: 1;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-8%);
  }
  65% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
  }
  50% {
    -webkit-transform: translateY(-8%);
  }
  65% {
    -webkit-transform: translateY(4%);
  }
  80% {
    -webkit-transform: translateY(-4%);
  }
  95% {
    -webkit-transform: translateY(2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
@media (max-width: 1024px) {
  .clients_heading {
    margin-bottom: 35px;
  }
}
@media (max-width: 600px) {
  .backgroundImageright {
    right: -7px;
  }
  .clients-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 0rem;
  }
  .sec {
    padding-left: 0% !important;
  }
}
@media (min-width: 1440px) {
}
