/* Navbar styling */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 222222;
  transition: background-color 0.3s ease-in-out;
  

}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Container */
.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 7%;
  padding-right: 7%;
}

/* Logo */
.logo {
  width: 150px;
  transition: width 0.3s ease-in-out;
}

.smallLogo {
  width: 65px; /* Adjust the size as needed */
}

.logo img {
  width: 100%;
  margin: 10px;
  margin-left: 0px;
}

/* Menu Icon */
.menu_icon {
  display: none;
}

/* Navigation elements */
.navElements {
  display: flex;
  justify-content: center; /* Center the headings */
  align-items: center; /* Align items vertically */
  flex: 1; /* Allow navElements to fill space */

}

.navElements ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0;
  padding-top: 3%;
  flex-direction: row;
  gap: 90px; /* Space between headings */
}

.navElements ul li {
  text-align: center; /* Center align the text */
  font-size: 55px; /* Adjust heading font size */
  flex: 1; /* Ensure each heading takes equal space */


}

.navElements ul a {
  color: #ff62b6;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap; /* Prevent breaking */
  font-size: 25px; /* Default heading font size */
  font-weight: bold;
  padding: 10px 20px; /* Add padding for more spacing around text */

}

.navElements ul a:hover {
  background-image: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  -webkit-background-clip: text;
  color: transparent;
  transition: background-image 0.5s ease;

}

.navElements ul a.active {
  color: #ffffff;
  font-weight: 900;
  position: relative;
  font-size: 55px; /* Default heading font size */

  
}

.navElements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

/* Close icon section */
.X {
  position: absolute; /* Make it positioned absolutely */
  top: 0.1px; /* Position it from the top */
  right: 1px; /* Position it from the right */
  text-align: left;
  margin: 50px;
  display: flex !important;
  gap: 2rem;
}

.X span {
  color: white;
  font-size: 28px; /* Adjust font size */
  border: 1px solid white;
  padding: 15px;
  border-radius: 50%;
  width: 25px; /* Adjust size */
  height: 25px; /* Adjust size */
  display: flex;
  align-items: center;
  justify-content: center;
}
.border {
  width: 2px; /* Thickness of the border */
  height: 100vh; /* Full height */
  background-color: #ff62b6; /* Color of the border */
  position: absolute; /* Positioning */
  left: 50%; /* Center horizontally */
  top: 0; /* Start from the top */
  transform: translateX(-50%); /* Adjust for centering */
  z-index: 1; 
}


/* Addresses section */
.addresses {
  position: fixed;
   bottom: 1%;
  left: 0;
  right: 0; /* Takes the full width */
  background-color: rgba(0, 0, 0, 0.8); /* Optional: background color */
  color: rgb(255, 253, 253);
  padding: 30px; /* Adds some padding */
  text-align: center; /* Center align text */
  z-index: 222222; /* Make sure it's above other content */
  font-size: 15px; /* Adjust for smaller screens */ 
  border-top: 1px solid rgb(68, 59, 59);
  text-align: right;
  padding-right: 150px;
  padding-top: 40px;

}



.socialIcons {
  display: flex;
  justify-content: right;
  gap: 4px; /* Space between icons */
  margin-top: 10px;
  margin-right: 10px;
}

.socialIcons a {
  color: #ff62b6; /* Your link color */
  font-size: 14px; /* Adjust icon text size */
  display: flex; /* Use flex to align icon and text */
  align-items: right; /* Center align items vertically */
  transition: color 0.3s ease;
  text-decoration: none; /* Remove underline */
  margin-right:1px;
}

.socialIcons a:hover {
  color: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
}

.socialIcons svg {
  margin-right: 5px; /* Space between icon and text */
}


.X p {
  line-height: 24px;
  font-size: 17px;
}
.popover {
  
  position: fixed; /* Change to fixed to position it relative to the viewport */
  left: 0; /* Align it to the left */
  top: 30px; /* Adjust the top position as needed to fit under the navbar */
  width: 90vw; /* Set width to full viewport width */
  background-color: rgb(0, 0, 0);
  z-index: 900; /* Make sure it's above other content */
  border-radius: 0; /* Remove border radius for full-width effect */
  margin-top: 120px; /* Remove margin */
  max-height: 15%;
  margin-right:145px;
  margin-left:45px;

}

.popoverContent {
  text-align: center;
  padding: 5px; /* Add some padding */
}

.popoverContent h4 {
  margin: 10px 0;
}

.serviceBox {
  display: flex; /* Use flexbox to arrange items in a row */ 
  justify-content: space-between; /* Space between columns */
  margin-top: 10px; /* Adds space above the service box */
}

.serviceItem {
  flex: 1; /* Each service item will take equal space */
  margin-right: 80px; /* Space between columns */
  cursor: pointer; /* Change cursor to pointer */

}

/* Remove the margin from the last item to avoid extra space */
.serviceItem:last-child {
  margin-right: 0;
}

.serviceItem h5 {
  margin: 0; /* Remove default margin */
  font-weight:bold ; /* Make headings bold */
  color: #fff !important;
  text-align: center;
  font-size: 17px;
}
.serviceItem p {
  margin-top: 1px; /* Reduced space between paragraphs */
  color: #fff;
  text-align: center;
  font-size: 12px; /* Adjusted font size for links in popover */
}

.popoverContent a {
  color: #ffffff !important; /* Your desired color for popup links */
  text-decoration: none !important; /* Remove underline */
  font-size: 14px !important; /* Set your desired font size for popup links */
  transition: color 0.3s ease !important; /* Add transition for hover effect */
}
.popoverContent a:hover {
  color: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%)!important; /* Change color on hover */
}
.box {
  --g: 18px;             /* the gap */
  --b: 2px;            /* border thickness */
  --c: #ff0088;         /* the color */
  
  padding: calc(var(--g) + var(--b)); /* Padding around the box */
  border: 2px solid transparent; /* Set a transparent border to maintain layout */
  
  --_c: #0000 0 25%, var(--c) 0 50%; /* Color settings */
  --_g1: repeating-linear-gradient(90deg, var(--_c)) repeat-x; /* Horizontal gradient */
  --_g2: repeating-linear-gradient(180deg, var(--_c)) repeat-y; /* Vertical gradient */
  
  background: 
    var(--_g1) var(--_p, 25%) 0, var(--_g2) 0 var(--_p, 125%),
    var(--_g1) var(--_p, 125%) 100%, var(--_g2) 100% var(--_p, 25%);
    
  background-size: 200% var(--b), var(--b) 200%; /* Background size */
  cursor: pointer; /* Pointer cursor on hover */
  filter: grayscale(50%); /* Initial grayscale effect */
  transition: .3s; /* Transition for hover effects */
}

.box:hover {
  --_p: 75%; /* Change to a different percentage on hover */
  filter: grayscale(0%); /* Remove grayscale effect on hover */
  transform: scale(1.05); /* Slightly enlarge the box on hover */
}



/* Mobile and responsive adjustments */
@media (max-width: 600px) {
  .logo {
    width: 100px;
  }

  .smallLogo {
    width: 70px;
  }

  .navElements ul a {
    font-size: 30px; /* Adjust font size for mobile */
  }

  .X span {
    position: absolute;
    top: 30px;
    right: 43px;
  }

  .X p {
    font-size: 14px !important;
    line-height: 21px !important;
    margin-bottom: 10px;
  }

  .navElements ul {
    margin: 50px;
    margin-bottom: 0px;
  }

  .X {
    margin-top: 0px;
  }

  .navElements.active {
    min-height: auto;
  }
}

@media (min-width: 600px) {
  .navElements.active ul {
    flex-direction: row; /* Keep headings in a row */
    margin-left: 0;
    margin-top: 0;
    justify-content: space-around; /* Ensure space between items */

  }

  .navElements.active ul li {
    margin-right: 30px;
    margin-top: 0;
    
  }

  .navElements.active ul a {
    font-size: 25px;
    font-weight: bold;
  }
  

}

@media (max-width: 5000px) {
  .menu_icon {
    display: block;
    cursor: pointer;
  }

  .navElements {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #000000;
    width: 100%;
    height: 0;
    transition: all 0.3s ease;
    overflow: hidden;
    z-index: 11111;
  }

  .navElements.active {
    height: 100vh;
  }

  .navElements ul {
    display: flex;
    justify-content: space-around;
    flex-direction: row; /* Keep headings next to each other */
    height: 100%;
    margin: 0;
    gap: 60px; /* Space between headings */
  }

  .navElements ul li {
    margin: 0; /* Reset margin */
  }
}
