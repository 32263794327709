.WeArCom {
  background: url(../../../assets/images/about-bg-01.png) top right no-repeat;

  padding-left: 7%;
  padding-right: 7%;
}
.left_c {
  width: 55%;
  padding-top: 200px;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
@media (max-width: 600px) {
  .left_c {
    width: 100%;
    padding: 20px 0px;
  }
}
