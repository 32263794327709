.pre_f {
  background-color: black;
}
.Footer {
  text-align: left;
  margin-top: 50px;
  padding-left: 7%;
  padding-right: 7%;
  position: relative;
  z-index: 12;
}
.Footer .ist_ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.Footer .one {
  width: 47.5%;
}
.Footer .two {
  width: 15.5%;
}
.Footer .three {
  width: 19%;
}
.Footer .four {
  width: 18%;
}
.fadein {
  opacity: 1;
  transition: all 1s;
  transform: translate(0, 100px);
}
.Footer li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
}
.m_b {
  margin-bottom: 10px;
}
.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
.Footer .one_flex {
  display: flex;
  flex-wrap: wrap;
}
.one_flex div {
  width: 100%;
}
.one h3 {
  color: #fff;
  font-size: 22px;
  line-height: 23px;
  margin-bottom: 20px;
  font-family: "gilroybold";
}
.Footer p {
  font-size: 12px;
  line-height: 21px;
  color: #fff;
  margin-bottom: 20px;
  font-weight: 400;
}
.Footer .two li {
  padding: 6px 10px;
  width: 75%;
  font-size: 12px;
  color: #e7e7e7;
  border: 1px solid #555;
  border-radius: 25px;
  margin-bottom: 10px;
}
.Footer .two li:hover {
  background-color: blue;
  cursor: pointer;
  transition: 0.5s ease;
}
.Footer .three li {
  padding: 6px 10px;
  width: 75%;
  font-size: 12px;
  color: #e7e7e7;
  border: 1px solid #555;
  border-radius: 25px;
  margin-bottom: 10px;
}
.Footer .three li:hover {
  background-color: blue;
  cursor: pointer;
  transition: 0.5s ease;
}
.Footer .four li {
  padding: 6px 10px;
  width: 75%;
  font-size: 12px;
  color: #e7e7e7;
  border: 1px solid #555;
  border-radius: 25px;
  margin-bottom: 10px;
}
.Footer .four li:hover {
  background-color: blue;
  cursor: pointer;
  transition: 0.5s ease;
}
.f_b {
  display: flex;
  flex-wrap: wrap;
}
.f_b_item_left {
  width: 25%;
}
.f_b_item_left ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.f_b_item_right {
  width: 75%;
}
.f_b_item_right ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.f_b_item_right ul li {
  color: #fff;
  font-size: 18px;
  font-family: "gilroyregular";
}
.span {
  color: #fff;
  font-size: 14px;
  text-align: center;
  width: 100%;
  padding: 15px 0px;
  
}
hr {
  background-color: #494949;
  height: 1px;
  border: none;
}
.two ul {
  margin: 0;
  padding: 0;
}
.three ul {
  margin: 0;
  padding: 0;
}
.four ul {
  margin: 0;
  padding: 0;
}

@media (max-width: 600px) {
  .Footer .ist_ul {
    flex-direction: column;
  }
  .Footer .one_flex {
    flex-direction: column;
  }
  .Footer .one {
    width: 100%;
  }
  .Footer .two {
    width: 100%;
  }
  .Footer .three {
    width: 100%;
  }
  .Footer .four {
    width: 100%;
  }
  .two ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .three ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .four ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  ul {
    margin: 0;
    padding: 0;
  }
  .f_b {
    flex-direction: column;
  }
  .f_b_item_right {
    width: 100%;
  }
  .f_b_item_right ul li {
    font-size: 13px;
  }
}
@media (min-width: 1440px) {
  .Footer {
    padding-left: 3%;
    padding-right: 3%;
  }
}
