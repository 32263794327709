.main_cont {
  background: url(../../../assets/images/contact_bg.png) right bottom no-repeat;
}
.contact {
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 150px;
  padding-bottom: 50px;
}
.contact_flex {
  display: flex;
}
.contact_flex ul {
  display: flex;
  flex-direction: column;
}
.contact_flex div {
  width: 50%;
}
.contact_flex input {
  width: 100%;
  height: 40px;
  background: hsla(0, 0%, 100%, 0.1);
  border: 1px solid #4e4e4e;
  outline: none;
  color: #fff;
  padding: 10px 3%;
  font-size: 15px;
  border-radius: 40px;
}
.contact_flex textarea {
  width: 100%;
  height: 140px;
  background: hsla(0, 0%, 100%, 0.1);
  border: 1px solid #4e4e4e;
  outline: none;
  color: #fff;
  padding: 10px 3%;
  font-size: 15px;
  border-radius: 10px;
}
.contact_flex h3 {
  background: linear-gradient(90deg, #7568dc 0%, #ff1493 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 100px;
  margin-bottom: 25px;
  line-height: 100px;
  font-family: "gilroybold";
}
.contact_flex p {
  color: white;
  font-size: 18px;
}
.contact_flex button {
  height: 47px;
  padding: 0 35px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 30px;
  cursor: pointer;
}
.contact_flex button:hover {
  background: linear-gradient(90deg, #7568dc 0%, #ff1493 100%);
  transition: 0.5s ease;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}

@media (min-width: 1440px) {
  .contact_flex h3 {
    font-size: 120px;
    line-height: 125px;
  }
}
@media (max-width: 600px) {
  .contact_flex {
    flex-direction: column;
  }
  .contact_flex div {
    width: 100%;
  }
  .contact_flex input {
    width: 92%;
  }
  .contact_flex textarea {
    width: 92%;
  }
  .contact_flex h3 {
    font-size: 30px !important;
    line-height: 33px !important;
  }
  .contact_flex p {
    margin-bottom: 30px;
  }
  .contact {
    padding-top: 80px;
  }
}
