.main {
  background: url(../../../assets/images/career-bg-01.png) right top no-repeat;
  padding-bottom: 100px;

}
.main_in {
  display: flex;
  gap: 3rem;
  margin-bottom: 300px;
  padding-left: 7%;
  padding-right: 7%;
}

.main h {
  background: linear-gradient(90deg, #7568dc 0%, #ff1493 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 80px;
  margin-bottom: 25px;
  line-height: 95px;
  font-family: "gilroybold";
}
.main p {
  margin-bottom: 20px;
  margin-top: 0;
  font-weight: 400;
  line-height: 24px;
  color: white;
  font-size: 15px;
  line-height: 20px;
}
.main_in div {
  width: 50%;
}
.main button {
  background-color: transparent;
  border: 1px solid white;
  padding: 12px 16px;
  color: white;
  border-radius: 30px;
  cursor: pointer;
  font-size: 15px;
}
.main button:hover {
  background: linear-gradient(90deg, #7568dc 0%, #ff1493 100%);
  transition: 0.5s ease-in;
}

hr {
  border: none;
  height: 1px;
  background-color: aliceblue;
}
.main_most {
  background: url(../../../assets/images/career-bg-2.png) right bottom 5%
    no-repeat;
  padding-bottom: 200px;
}
.main_below {
  padding-left: 7%;
  padding-right: 7%;
}
.below_flex {
  display: flex;
  gap: 3rem;
  padding-top: 100px;
}
.below_flex h2 {
  background: linear-gradient(90deg, #7568dc 0%, #ff1493 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 55px;
  line-height: 60px;
}
.below_flex p {
  color: #fff;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 35px;
}
.below_flex div {
  width: 50%;
}
.below_flex h5 {
  font-size: 20px;
  font-family: "gilroybold";
  font-weight: inherit;
  color: #fff;
  margin-bottom: 20px;
}
.below_flex ul {
  margin: 0 0 45px;
  padding: 0 0 0 18px;
}
.below_flex ul li {
  color: white;
  list-style-type: disc;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 19px;
}
.hr {
  width: 70%;
  padding-left: 15%;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
@media (min-width: 1440px) {
  .main h3 {
    font-size: 120px;

    line-height: 125px;
  }
  .main_in,
  .main_below {
    padding-left: 3%;
    padding-right: 3%;
  }
  .below_flex h2 {
    font-size: 60px;
    line-height: 81px;
    letter-spacing: -1px;
  }
  .main p {
    font-size: 20px;
    line-height: 22px;
  }
  .main ul li {
    color: #fff;
    font-size: 18px;
    margin-bottom: 11px;
  }
}
@media (max-width: 600px) {
  .main {
    padding-top: 10px;
  }
  .main_in {
    flex-direction: column;
    gap: 0rem;
  }
  .main_in div {
    width: 100%;
  }
  .below_flex {
    flex-direction: column;
    padding-top: 30px;
    gap: 0rem;
  }
  .below_flex div {
    width: 100%;
  }
  .below_flex h2 {
    font-size: 35px;
    line-height: 41px;
    margin-bottom: 10px;
  }
  .main h3 {
    font-size: 44px;
    line-height: 48px;
    margin-bottom: 8px !important;
  }
  .below_flex h5 {
    margin-top: 10px;
    font-size: 17px;
  }
  .below_flex p {
    font-size: 15px !important;
    line-height: 20px !important;
  }
  .below_flex ul li {
    font-size: 15px !important;
    line-height: 20px !important;
  }
}
