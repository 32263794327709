@font-face {
  font-family: icomoon;
  src: url(./assets/fonts/icomoon.23fad797.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: metropolislight;
  src: url(./assets/fonts/metropolis-bold-webfont.3cc3281a.woff2);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: proxima_novalight;
  src: url(./assets/fonts/proximanova-600-webfont.ef63c678.woff2);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: aktivgrotesk-regu;
  src: url(./assets/fonts/aktivgrotesk-bold-webfont.915a6f1a.woff2);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: aktivgrotesk-bold;
  src: url(./assets/fonts/aktivgrotesk-xbold-webfont.93fbe858.woff2);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: gilroymedium;
  src: url(./assets/fonts/gilroy-medium-webfont.e41d2429.woff2);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: gilroybold;
  src: url(./assets/fonts/gilroy-bold-webfont.8bcc5318.woff2);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: gilroyregular;
  src: url(./assets/fonts/gilroy-regular-webfont.fc153085.woff2);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Cairo;
  src: url(./assets/fonts/Cairo-Regular.80fa88dc.woff);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: gilroyregular, Cairo;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin-bottom: -20px;
  overflow-x: hidden;
  background-color: black;
  /* overflow: auto; */
}
@media (max-width: 768px) {
  html,
  body {
    /* overflow-x: hidden !important; */
  }
}
/* styles.module.css or any other CSS file */
.no-scrollbar .scroll-content::-webkit-scrollbar {
  display: none !important;
  overflow: hidden !important;
}

.no-scrollbar .scroll-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: hidden;
}
html {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
#scroll-container {
  overflow: hidden;
}
h1,
h2,
h3,
h4.h5.h6,
p {
  margin: 0;
  padding: 0;
}
.whatsappWidget {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 11111111;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.whatsappWidget img {
  width: 100%;
}
.sameH {
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 57px;
  line-height: 55px;
  font-family: gilroybold, Cairo;
  margin-bottom: 30px;
  text-align: left;
}
.sameP {
  color: #fff;
  font-size: 18px !important;
  margin-bottom: 20px !important;
  margin-top: 0 !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: left !important;
}
.sameH,
.samePp {
  transition: opacity 1s ease-in-out; /* Ensure headings and paragraphs have the same transition */
}
.sameB {
  margin-bottom: 30px;
  height: 47px;
  border: 2px solid hsla(0, 0%, 100%, 0.2);
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  color: #fff;
  display: inline-flex;
  padding: 0 30px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-radius: 100px;
  cursor: pointer;
  color: #fff !important;
  font-weight: 600;
  opacity: 0.8;
}
.sameB:hover {
  opacity: 1;
  transition: 0.3s ease;
}
.sameI {
  font-size: 38px;
  line-height: 50px;
  letter-spacing: 0px;
  margin: 0 0 25px;
  text-align: left;
  color: white;
  font-family: gilroybold, Cairo;
}
.sameD {
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  margin-bottom: 20px;
  color: white;
}
ul li {
  list-style-type: none;
}
.sameHh {
  background: linear-gradient(90deg, #7568dc 0%, #ff1493 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 35px;
  line-height: 55px;
  font-family: gilroybold, Cairo;
  margin-bottom: 30px;
  text-align: left;
}
@media (max-width: 1024px) {
  .sameH {
    margin-bottom: 9px;
    font-size: 50px;
    line-height: 50px;
  }
  .sameP {
    font-size: 15px !important;
    line-height: 20px !important;
  }
  .sameB {
  }
}
@media (max-width: 600px) {
  .sameH {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 33px;
  }
  .sameP {
    font-size: 14px !important;
    line-height: 21px !important;
    margin-bottom: 10px !important;
  }
  .sameB {
    height: 40px;
    border-radius: 50px;
    font-size: 12px;
    margin-bottom: 15px;
  }
  .sameI {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0;
    margin-bottom: 10px;
  }
  .sameD {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  }
}
@media (min-width: 1440px) {
  .sameH {
    color: #fff;
    font-size: 69px;
    line-height: 70px;

    font-weight: 400;
  }
  .sameB {
    height: 55px;
    border: 2px solid hsla(0, 0%, 100%, 0.2);
    background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
    color: #fff;
    display: inline-flex;
    padding: 0 30px;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    border-radius: 100px;
    cursor: pointer;
    color: #fff !important;
    transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -webkit-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
  }
  .sameI {
    font-size: 43px;
    letter-spacing: 0px;
    line-height: 57px;
    letter-spacing: 0px;
    margin: 0 0 25px;
  }
  .sameD {
    font-size: 20px;
  }
}
