@media (min-width: 1440px) {
  .content h2 {
    font-size: 140px;
    line-height: 125px;
    letter-spacing: -4px;
    background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .content h5 {
    color: #fff;
    font-size: 20px;
    font-weight: 400;

    max-width: 90%;
  }
  .content {
    padding: 0% 3%;
    padding-top: 80px;
  }
  .content_below {
    padding: 0% 3%;
  }
  .txt {
    width: 65%;
  }
  .txt h3 {
    color: #fff;
    font-size: 69px !important;
    line-height: 70px !important;
    font-family: "gilroybold";
    font-weight: 400;
    margin-bottom: 35px;
  }
}
.content {
  padding: 0% 7%;
  padding-top: 80px;
}
.content_below {
  padding: 30px 7%;
}
.txt {
  width: 50%;
}
.txt h3 {
  color: white;
  font-size: 57px;
  line-height: 55px;
  padding-top: 80px;
  font-family: "gilroybold";
}
.content h2 {
  font-size: 110px;
  line-height: 100px;
  letter-spacing: 0;
  background: linear-gradient(90deg, #7568dc 0%, #f74ba6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content h5 {
  font-size: 18px;
  color: white;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
@media (max-width: 600px) {
  .content h2 {
    font-size: 47px;
    line-height: 45px;
    letter-spacing: -2px;
  }
  .txt {
    width: 100%;
  }
  .txt h3 {
    font-size: 30px !important;
    line-height: 33px !important;
    margin-bottom: 20px;
  }
}
