body {
  background-color: black;
}
.detail_main h1 {
  background: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 55px;
  line-height: 67px;
  font-family: gilroybold, Cairo;
  font-weight: 600 !important;
  text-align: left;
}
.top_banner {
  width: 100%;
}
.top_banner img {
  width: 100%;
  /* height: 100vh; */
  max-height: 100vh;
}
.char {
  display: inline-block;
  opacity: 0.5; /* Start with low opacity */
}

.left_pic {
  display: flex;
  align-items: center;
  gap: 2%;
  place-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.left_pic_items {
  width: 50%;
}
.fadein {
  opacity: 0.2;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
#gh {
  text-align: center;
  margin-top: 50px;
  color: white !important;
  background: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 55px;
  line-height: 55px;
  font-family: gilroybold, Cairo;
  margin-bottom: 30px;
  text-align: center;
}
.mj .top_banner{
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: end;

}
.mj .top_banner  h1{
  width: 80%;
  margin: 0 auto;
}
#sameP {
  font-size: 32px;
  font-weight: 600;
  line-height: 37px;
}
#fHh {
  font-size: 36px !important;
  line-height: 40px !important;
  margin-bottom: 15px !important;
}
.right_pic {
  display: flex;
  align-items: center;
  gap: 2%;
  place-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.right_pic_items {
  width: 50%;
}
.left_pic img {
  width: 100%;
}
.right_pic img {
  width: 100%;
}
.detail_main {
  padding-left: 7%;
  padding-right: 7%;
  background-color: black;
  padding-top: 70px;
}
.detail_main p {
  color: #fff;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}
.detail_main ul li {
  color: white;
  font-size: 20px;
  line-height: 30px;
  list-style: disc!important;
}

@media (min-width: 1440px) {
  .detail_main {
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 120px;
  }
  .detail_main h1 {
    font-size: 70px;
  }
}
@media (max-width: 768px) {
  .right_pic {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .left_pic {
    flex-direction: column-reverse;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .right_pic_items {
    width: 100%;
  }
  .left_pic_items {
    width: 100%;
  }
  .detail_main h1 {
    font-size: 32px;
    line-height: 32px;
  }
  #fHh {
    font-size: 22px !important;
  }
  .detail_main {
    padding-top: 30px;
  }
  .right_pic {
    padding-top: 0px;
  }
  .detail_main p {
    font-size: 18px;
  }
  .top_banner img {
    height: auto !important;
  }
  #sameP {
    font-size: 22px !important;
  }
  #gh {
    font-size: 32px !important;
    line-height: 32px !important;
  }
}
@media(max-width: 768px){
  .mj .top_banner{
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: end;

  
  }
}