.coreS {
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 80px;
  background-color: #070707;
  position: relative;
  z-index: 111;
}
.big {
  font-size: 90px;
  line-height: 90px;
}
.c_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c_item div {
  width: 50%;
}
.c_item img {
  width: 100%;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}
@media (min-width: 1440px) {
  .coreS {
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 80px;
    background-color: #070707;
    position: relative;
    z-index: 111;
  }
  .big {
    font-size: 120px !important;
    line-height: 110px !important;
  }
}
@media (max-width: 600px) {
  .c_item {
    flex-direction: column;
  }
  .c_item div {
    width: 100%;
  }
  .big {
    font-size: 34px;
    line-height: 39px !important;
    letter-spacing: -2px;
  }
}
