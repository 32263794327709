/* jobApplication.module.css */
.mainContainer {
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 150px;
    padding-bottom: 50px;
  }
  
  .jobApplicationForm {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  /* Left Side: Centered Content */
  .leftContent {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center the content */
    height: 70vh; /* Make it take full viewport height */
  }
  
  .leftContent h3 {
    background: linear-gradient(90deg, #7568dc 0%, #ff1493 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 100px;
    margin-bottom: 25px;
    line-height: 100px;
    font-family: "gilroybold";
  }
  
  .leftContent p {
    color: rgb(0, 0, 0);
    font-size: 38px;
  }
  
  /* Right Side: Form Aligned at the Top */
  .rightForm {
    width: 50%;
    align-self: flex-start; /* Align the form to the top */

    
  }
  
  .rightForm ul {
    display: flex;
    flex-direction: column;
  }
  
  .rightForm input,
  .rightForm textarea {
    width: 100%;
    background: hsla(0, 0%, 100%, 0.1);
    border: 1px solid #4e4e4e;
    outline: none;
    color: #fff;
    padding: 10px 3%;
    font-size: 15px;
    border-radius: 40px;

  }
  
  .rightForm textarea {
    height: 140px;
    border-radius: 10px;
  }
  
  .rightForm button {
    height: 47px;
    padding: 0 35px;
    background-color: transparent;
    border: 1px solid white;
    color: white;
    border-radius: 30px;
    cursor: pointer;
  }
  
  .rightForm button:hover {
    background: linear-gradient(90deg, #7568dc 0%, #ff1493 100%);
    transition: 0.5s ease;
  }
  
  /* Fade-in Effect */
  .fadeIn {
    opacity: 0;
    transition: all 1.5s;
    transform: translate(0, 100px);
  }
  
  .fadeIn.visible {
    opacity: 1;
    transform: translate(0, 0);
  }
  /* jobApplication.module.css */
  /* Add this to your existing CSS file */
  
  .uploadButton {
    display: inline-block;
    background-color: transparent;
    border: 1px solid white; /* Border to match the button style */
    color: white;
    border-radius: 30px;
    cursor: pointer;
    padding: 10px 15px; /* Add padding for a button-like appearance */
    text-align: center; /* Center the text */
    transition: background 0.3s;
  }
  
  .uploadButton:hover {
    background: linear-gradient(90deg, #7568dc 0%, #ff1493 100%);
  }
  
  .fileInput {
    display: none; /* Hide the default file input */
  }
  .resumePreview {
    margin-top: 10px;
    color: white; /* Adjust text color as needed */
  }
  
  @media (min-width: 1440px) {
    .leftContent h3 {
      font-size: 120px;
      line-height: 125px;
    }
  }
  
  @media (max-width: 600px) {
    .jobApplicationForm {
      flex-direction: column;
    }
  
    .leftContent,
    .rightForm {
      width: 100%;
    }
  
    .rightForm input,
    .rightForm textarea {
      width: 92%;
    }
  
    .leftContent h3 {
      font-size: 30px !important;
      line-height: 33px !important;
    }
  
    .leftContent p {
      margin-bottom: 30px;
    }
  
    .mainContainer {
      padding-top: 80px;
    }
  }
  